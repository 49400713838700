.container {
  overflow: hidden;
  position: relative;
}

.container section {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
}

.container div {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.container section:hover {
  transform: scale(1.05);
}

.container div:hover {
  transform: scale(0.98);
}
