@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
/* .animate-modal > .content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
} */

.modal::-webkit-scrollbar {
  width: 15px; /* width of the entire scrollbar */
}
.modal::-webkit-scrollbar-track {
  background: #999999; /* color of the tracking area */
  border-radius: 20px;
}
.modal::-webkit-scrollbar-thumb {
  background-color: white; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll bar */
  border: 3px solid #999999; /* creates padding around scroll thumb */
}

.modal {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
  max-width: 85vw;
}

.modal > .close {
  z-index: 4000;
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 10px 10px;
  line-height: 10px;
  left: -15px;
  top: -17px;
  font-size: 20px;
  border-radius: 50px;
  outline: none;
  transition: all;
  transition-duration: 0.3s;
  border-color: white;
  border-width: 2px;
}

.popup-content {
  margin: auto;
  background: #fff;
  padding: 5px;
  border: 1px solid #d7d7d7;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  border-radius: 10px;
}

[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
}
